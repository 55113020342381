import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { SWRConfig } from "swr";

if (process.env?.NODE_ENV !== "development") {
  console.log = function () {};
}

export const ERROR_MESSAGE = process.env?.NODE_ENV === "development" ? true : false;
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  // <React.StrictMode>
  // <App />
  // </React.StrictMode>
  <SWRConfig
    value={{
      revalidateOnFocus: false,
      shouldRetryOnError: false,
    }}
  >
    <App />
  </SWRConfig>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
